import { colorLookup } from '@medifind/utils';
import { SVG } from './SVG';
const IconMarkerHollow = (props) => {
  const { color, width, height, style } = props;
  const colorCode = colorLookup(color) ?? colorLookup('blue');
  return (
    <SVG width={width || '16'} height={height || '18'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18" style={style}>
      <path
        stroke={colorCode}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14 7.67c0 4.66-6 8.66-6 8.66s-6-4-6-8.66a6 6 0 0 1 12 0z"
        clipRule="evenodd"
      />
      <path
        stroke={colorCode}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8 9.67a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
        clipRule="evenodd"
      />
    </SVG>
  );
};

export { IconMarkerHollow as default, IconMarkerHollow };
